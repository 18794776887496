var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { attrs: { title: "서명", height: "520px" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: {
                      color: "purple",
                      label: "초기화",
                      icon: "autorenew",
                      name: "col1",
                    },
                    on: { btnClicked: _vm.undo },
                  }),
                  _c("c-btn", {
                    attrs: { label: "LBLSAVE", icon: "save" },
                    on: { btnClicked: _vm.save },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("VueSignaturePad", {
                  ref: "signaturePad",
                  attrs: { width: "100%", height: "500px", id: "signature" },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }